import {
  SET_CONTRACT_DATA,
  APPLY_USER_SIGN_IN,
  APPLY_USER_SIGN_OUT,
  APPLY_CURRENT_USER_UPDATE,
  SET_USER_DATA,
  UPDATE_USER_PERMISSIONS,
  SET_VENDOR_DATA,
} from '../reducers/auth'

const applyUserSignIn = (userAuth, currentUser, isAppView) => ({
  type: APPLY_USER_SIGN_IN,
  payload: { userAuth, currentUser, isAppView },
})

const setVendorData = (payload) => ({
  type: SET_VENDOR_DATA,
  payload,
})

const applyUserSignOut = () => ({
  type: APPLY_USER_SIGN_OUT,
})

const applyCurrentUserUpdate = (currentUser) => ({
  type: APPLY_CURRENT_USER_UPDATE,
  payload: { currentUser },
})

const setContractData = (payload) => ({
  type: SET_CONTRACT_DATA,
  payload,
})

const setUserData = (payload) => ({
  type: SET_USER_DATA,
  payload,
})

const updateUserPermissions = (payload) => ({
  type: UPDATE_USER_PERMISSIONS,
  payload,
})

export {
  applyUserSignIn,
  applyUserSignOut,
  applyCurrentUserUpdate,
  setContractData,
  setVendorData,
  setUserData,
  updateUserPermissions,
}
