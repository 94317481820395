import { ApolloClient, InMemoryCache, from, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import appConfig from './appConfig'
import { applyUserSignOut } from '../store/actions/auth'
import store from '../store'
import { showNotification } from '../store/actions/notifications'
import { captureCritical, captureInfo } from '../utils/rollbar'
import { handleError } from '../utils/utils'
import { getFromSessionStorage, saveToSessionStorage } from '../utils/sessionStorage'
import { createUploadLink } from 'apollo-upload-client'

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL || appConfig.API_URL}/graphql`,
})

const getAuthToken = () => {
  const redux = localStorage.getItem('redux')

  if (redux) {
    const reduxData = JSON.parse(redux)

    return reduxData.auth.tokens.auth
  }
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from redux if it exists
  const token = getAuthToken()
  const isPublicQueryAuthorization = headers?.PublicQueryAuthorization
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...(token && !isPublicQueryAuthorization && { authorization: `Bearer ${token}` }),
    },
  }
})

const checkVersions = (headers) => {
  const savedAppVersion = getFromSessionStorage('suppli-app-version')
  const savedApiVersion = getFromSessionStorage('suppli-api-version')
  const currentAppVersion = headers.get('suppli-app-version')
  const currentApiVersion = headers.get('suppli-api-version')
  if (currentAppVersion && currentApiVersion && savedApiVersion && savedAppVersion) {
    if (savedAppVersion !== currentAppVersion || savedApiVersion !== currentApiVersion) {
      window.location.reload()
    }
  }
  if (currentAppVersion && currentApiVersion) {
    saveToSessionStorage('suppli-app-version', currentAppVersion)
    saveToSessionStorage('suppli-api-version', currentApiVersion)
  }
}

const versionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    checkVersions(operation.getContext().response.headers)
    return response
  })
})

const rollbarVariablesLink = new ApolloLink((operation, forward) => {
  const { variables } = operation

  return forward(operation).map((response) => {
    if (response.data && typeof response.data === 'object' && !Array.isArray(response.data)) {
      response.data.variables = variables
    }

    return response
  })
})

// Catch any network or server errors that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { operationName, getContext, variables } = operation
  const { rollbar, rollbarOptions } = getContext()
  const newNotification = (notification) => store.dispatch(showNotification(notification))

  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) =>
      captureCritical(rollbar, graphQLError, {
        path: graphQLError?.path?.join(' > ') || '',
        message: graphQLError.message,
        target: 'onErrorLink gql',
        operationName,
        operationVariables: JSON.stringify(variables),
        ...rollbarOptions,
      }),
    )
    handleError(graphQLErrors, newNotification)
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`)
    if (networkError.statusCode === 440) {
      store.dispatch(showNotification({ error: 'Current session has expired.' }))
      store.dispatch(applyUserSignOut())
    }
    const supportEmail = store.getState()?.auth?.currentVendor?.supportEmail
    if (networkError.statusCode === 423) {
      store.dispatch(
        showNotification({
          // eslint-disable-next-line max-len
          error: `This account is currently inactive. Please reach out to ${supportEmail} if you would like to reactivate your account`,
        }),
      )
      store.dispatch(applyUserSignOut())
    }
    if (networkError.statusCode === 401) {
      captureInfo(rollbar, networkError, {
        target: 'onErrorLink network',
        result: JSON.stringify(networkError.result),
        message: networkError.message,
        operationName,
        operationVariables: JSON.stringify(variables),
        ...rollbarOptions,
      })
      store.dispatch(applyUserSignOut())
    }
    if (networkError.statusCode === 404) {
      captureCritical(rollbar, networkError, {
        target: 'onErrorLink network',
        result: JSON.stringify(networkError.result),
        message: networkError.message,
        operationName,
        operationVariables: JSON.stringify(variables),
        ...rollbarOptions,
      })
      store.dispatch(
        showNotification({ error: networkError.result?.error || networkError.message }),
      )
    }
    if (networkError.statusCode === 500) {
      captureCritical(rollbar, networkError, {
        target: 'onErrorLink network',
        result: JSON.stringify(networkError.result),
        message: networkError.message,
        operationName,
        operationVariables: JSON.stringify(variables),
        ...rollbarOptions,
      })
      store.dispatch(showNotification({ error: 'An unexpected error has occurred.' }))
    }
  }
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  link: from([errorLink, authLink, versionLink, rollbarVariablesLink, uploadLink]),
  cache: new InMemoryCache(),
  defaultOptions,
})

export { client }
