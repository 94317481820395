import React from 'react'
import PT from 'prop-types'
import { Transition } from '@headlessui/react'

const Collapse = ({ isOpen, children, className }) => {
  return (
    <Transition
      className={className}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={isOpen}>
      {children}
    </Transition>
  )
}

Collapse.propTypes = {
  children: PT.node.isRequired,
  className: PT.string,
  isOpen: PT.bool,
}

Collapse.defaultProps = {
  className: '',
  isOpen: false,
}

export default Collapse
