export const saveToSessionStorage = (key, data) => {
  try {
    const serialisedState = JSON.stringify(data)
    sessionStorage.setItem(key, serialisedState)
  } catch (e) {
    console.warn(e)
  }
}

export const getFromSessionStorage = (key) => {
  try {
    const serialisedState = sessionStorage.getItem(key)
    if (serialisedState === null) return undefined
    return JSON.parse(serialisedState)
  } catch (e) {
    console.warn(e)
    return undefined
  }
}
