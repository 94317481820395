import React from 'react'
import PT from 'prop-types'

const DotIcon = ({ className, onClick }) => (
  <svg className={className} fill="currentColor" onClick={onClick} viewBox="0 0 20 20">
    <circle cx={10} cy={10} r={3} />
  </svg>
)

DotIcon.propTypes = {
  className: PT.string,
  onClick: PT.func,
}

DotIcon.defaultProps = {
  className: '',
  onClick: null,
}

export default DotIcon
