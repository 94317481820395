import {
  RESET_DIRTY_FORM_ALERT_STATE,
  SET_ALERT_MODAL_OPENED_STATE,
  SET_DIRTY_FORM_STATE,
  UPDATE_DIRTY_FORM_ALERT_STATE,
} from '../reducers/dirtyFormAlert'

export const setDirtyFormStateStore = (payload) => ({
  type: SET_DIRTY_FORM_STATE,
  payload,
})

export const setAlertModalOpenedStateStore = (payload) => ({
  type: SET_ALERT_MODAL_OPENED_STATE,
  payload,
})

export const updateDirtyFormAlertState = (payload) => ({
  type: UPDATE_DIRTY_FORM_ALERT_STATE,
  payload,
})

export const resetDirtyFormAlertState = () => ({
  type: RESET_DIRTY_FORM_ALERT_STATE,
})
