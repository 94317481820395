import { MONEY } from '../../../utils/dataTypes'
import { camelCase } from 'lodash/string'

export const getValuesString = (values) => {
  if (Array.isArray(values)) {
    return values.join('||')
  } else {
    return values
      .split(',')
      .map((value) => value.trim())
      .join('-')
  }
}

export const checkIfConvert = (value, filter) => {
  return filter.dataType === MONEY && value && !isNaN(value)
}

export const convertToCents = (value, filter) => {
  return checkIfConvert(value, filter) ? value * 100 : value
}

export const convertToDollar = (value, filter) => {
  return checkIfConvert(value, filter) ? value / 100 : value
}

export const getFromAvailableFilters = (filterId, availableFilters) =>
  availableFilters.find((item) => camelCase(item.key) === camelCase(filterId))

export const validateFilters = (filters) =>
  Object.keys(filters).reduce((acc, filterKey) => {
    const validFilterValues = filters[filterKey].filter((value) => {
      if (!value && (value !== 0 || value !== '')) {
        return false
      }

      return true
    })

    if (!validFilterValues.length) {
      return acc
    }

    if (filters[filterKey].length === validFilterValues.length) {
      return {
        ...acc,
        [filterKey]: filters[filterKey],
      }
    }

    return {
      ...acc,
      [filterKey]: validFilterValues,
    }
  }, {})
