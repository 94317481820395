import React from 'react'
import PT from 'prop-types'

const ConnectorHorIcon = ({ className, onClick }) => (
  <svg
    className={className}
    fill="currentColor"
    height="64"
    onClick={onClick}
    viewBox="0 0 64 6"
    width="6"
    xmlns="http://www.w3.org/2000/svg">
    <rect fill="#E5E7EB" height="6" width="64" />
  </svg>
)

ConnectorHorIcon.propTypes = {
  className: PT.string,
  onClick: PT.func,
}

ConnectorHorIcon.defaultProps = {
  className: '',
  onClick: null,
}

export default ConnectorHorIcon
