export const BURGER_MENU_KEY = 'burger-menu'

const actions = {}
const initialState = {
  isOpened: false,
}

// ------------------------------------
// Reducer
// ------------------------------------

const reducer = (state = initialState, action) => {
  const handler = actions[action.type]

  return handler ? handler(state, action) : state
}

export default reducer

// ------------------------------------
// Actions
// ------------------------------------

// User sign in
export const OPEN_BURGER_MENU = `${BURGER_MENU_KEY}/OPEN_BURGER_MENU`
actions[OPEN_BURGER_MENU] = () => ({ isOpened: true })

// User sign out
export const CLOSE_BURGER_MENU = `${BURGER_MENU_KEY}/CLOSE_BURGER_MENU`
actions[CLOSE_BURGER_MENU] = () => ({ isOpened: false })
