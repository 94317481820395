import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './locales/en.json'
import es from './locales/es.json'

const initLocales = () => {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      detection: {
        order: ['localStorage'],
        lookupLocalStorage: 'i18Lng',
      },
      resources: {
        en: {
          translation: en,
        },
        es: {
          translation: es,
        },
      },
      lng: 'en',
      fallbackLng: 'en',

      interpolation: {
        escapeValue: false,
      },
    })
}
export default initLocales
