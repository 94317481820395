export const FILTERS_KEY = 'filters'

const actions = {}
const initialState = {
  showFilters: false,
}

// ------------------------------------
// Reducer
// ------------------------------------

const reducer = (state = initialState, action) => {
  const handler = actions[action.type]

  return handler ? handler(state, action) : state
}

export default reducer

// ------------------------------------
// Actions
// ------------------------------------

// Apply show filter change
export const SHOW_FILTERS = `${FILTERS_KEY}/SHOW_FILTERS`
export const HIDE_FILTERS = `${FILTERS_KEY}/HIDE_FILTERS`

actions[SHOW_FILTERS] = () => ({ showFilters: true })
actions[HIDE_FILTERS] = () => ({ showFilters: false })
