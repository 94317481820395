import appConfig from '../configs/appConfig'
import { Userpilot } from 'userpilot'

//Initialize Userpilot
export const initUserpilot = () => {
  if (appConfig.USERPILOT_TOKEN) {
    Userpilot.initialize(appConfig.USERPILOT_TOKEN)
  }
}

export const identityUserpilot = (user) => {
  if (appConfig.USERPILOT_TOKEN) {
    Userpilot.identify(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      merchantDba: user.vendorName,
      customerContactName: user.companyName,
      role: user.companyRole,
    })
  }
}

export const reloadUserpilot = () => {
  if (appConfig.USERPILOT_TOKEN) {
    Userpilot.reload()
  }
}
