import React from 'react'
import PT from 'prop-types'
import colors from '../../colors'
import Icon from '../icons/Icon'
import sizes from '../../sizes'

const InfoNote = ({ iconName, label, children, withIcon }) => (
  <div className="flex items-center">
    {withIcon && (
      <div className="p-0.5">
        <Icon color={colors.GREY} name={iconName} size={sizes.BASE} type="solid" />
      </div>
    )}

    <div className="pr-0.5 text-sm text-warmBlack-400">{`${label}:`}</div>

    <div className="p-0.5 text-sm">{children}</div>
  </div>
)

InfoNote.propTypes = {
  children: PT.node.isRequired,
  iconName: PT.string,
  label: PT.string.isRequired,
  withIcon: PT.bool,
}

InfoNote.defaultProps = {
  iconName: '',
  withIcon: false,
}

export default InfoNote
