import PT from 'prop-types'
import { DEFAULT_DATE_FORMAT, getFormattedDate } from '../../utils/dateUtils'

const FormattedDate = ({ date, format }) => {
  return date ? getFormattedDate(date, format) : '-'
}

FormattedDate.propTypes = {
  date: PT.string,
  format: PT.string,
}

FormattedDate.defaultProps = {
  date: null,
  format: DEFAULT_DATE_FORMAT,
}

export default FormattedDate
