import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from '../reducers/notifications'

const showNotification = (newNotification) => ({
  type: SHOW_NOTIFICATION,
  payload: newNotification,
})

const hideNotifications = () => ({
  type: HIDE_NOTIFICATION,
})

export { showNotification, hideNotifications }
