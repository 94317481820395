import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { values } from 'lodash'

import colors from '../../colors'
import sizes from '../../sizes'
import Text from './Text'

const Heading = ({ color, size, tag, children, subHeading, className, isBold }) => {
  const headingClassnames = cx(
    'heading',
    { [color]: color, 'font-bold': isBold },
    `text-${size}`,
    className,
  )

  const Element = tag

  return (
    <Element className={headingClassnames}>
      {children}

      {subHeading !== null && <Text color={colors.GREY} size={size}>{` (${subHeading})`}</Text>}
    </Element>
  )
}

Heading.propTypes = {
  children: PT.node.isRequired,
  className: PT.string,
  color: PT.oneOf(values(colors)),
  isBold: PT.bool,
  size: PT.oneOf(values(sizes)),
  subHeading: PT.oneOfType([PT.string, PT.number]),
  tag: PT.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}

Heading.defaultProps = {
  color: colors.BLACK,
  className: '',
  isBold: true,
  size: sizes.XL3,
  subHeading: null,
  tag: 'h3',
}

export default Heading
