import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDirtyFormStateStore,
  setAlertModalOpenedStateStore,
  updateDirtyFormAlertState,
  resetDirtyFormAlertState,
} from '../store/actions/dirtyFormAlert'
import {
  selectIsFormDirty,
  selectIsAlertModalOpened,
  selectFormAlertState,
} from '../store/selectors/dirtyFormAlert'

export const useDirtyFormAlert = ({ closeForm, id } = {}) => {
  const dispatch = useDispatch()

  const state = useSelector(selectFormAlertState)
  const isFormDirty = useSelector(selectIsFormDirty)
  const isAlertModalOpened = useSelector(selectIsAlertModalOpened)

  const setDirtyFormState = useCallback(
    (payload) => {
      if (id) {
        dispatch(
          updateDirtyFormAlertState({
            key: `isFormDirty-${id}`,
            value: payload,
          }),
        )
      } else {
        dispatch(setDirtyFormStateStore(payload))
      }
    },
    [dispatch, id],
  )
  const setAlertModalOpenedState = useCallback(
    (payload) => {
      if (id) {
        dispatch(
          updateDirtyFormAlertState({
            key: `isAlertModalOpened-${id}`,
            value: payload,
          }),
        )
      } else {
        dispatch(setAlertModalOpenedStateStore(payload))
      }
    },
    [dispatch, id],
  )
  const requestClose = useCallback(() => {
    const isFormDirtyState = id ? state[`isFormDirty-${id}`] : isFormDirty
    isFormDirtyState ? setAlertModalOpenedState(true) : closeForm()
  }, [closeForm, isFormDirty, setAlertModalOpenedState])
  const closeAlertModal = useCallback(
    () => setAlertModalOpenedState(false),
    [setAlertModalOpenedState],
  )
  const resetDirtyFormAlertStates = useCallback(
    () => dispatch(resetDirtyFormAlertState()),
    [dispatch],
  )

  useEffect(() => {
    return () => {
      setDirtyFormState(false)
      setAlertModalOpenedState(false)
    }
  }, [setAlertModalOpenedState, setDirtyFormState])

  return {
    isFormDirty: id ? state[`isFormDirty-${id}`] : isFormDirty,
    requestClose,
    closeAlertModal,
    setDirtyFormState,
    isAlertModalOpened: id ? state[`isAlertModalOpened-${id}`] : isAlertModalOpened,
    resetDirtyFormAlertStates,
  }
}
