export const NOTIFICATIONS_KEY = 'notifications'

const actions = {}
const initialState = {
  notifications: {},
}

// ------------------------------------
// Reducer
// ------------------------------------

const reducer = (state = initialState, action) => {
  const handler = actions[action.type]

  return handler ? handler(state, action) : state
}

export default reducer

// ------------------------------------
// Actions
// ------------------------------------

// Apply notification change
export const SHOW_NOTIFICATION = `${NOTIFICATIONS_KEY}/SHOW_NOTIFICATION`
export const HIDE_NOTIFICATION = `${NOTIFICATIONS_KEY}/HIDE_NOTIFICATION`

actions[SHOW_NOTIFICATION] = (state, { payload }) => ({ notifications: payload })
actions[HIDE_NOTIFICATION] = () => ({ notifications: {} })
