import { combineReducers } from 'redux'
import authReducer, { AUTH_KEY } from './reducers/auth'
import breadcrumbsReducer, { BREADCRUMBS_KEY } from './reducers/breadcrumbs'
import deviceInfoReducer, { DEVICE_INFO_KEY } from './reducers/deviceInfo'
import filtersReducer, { FILTERS_KEY } from './reducers/filters'
import burgerMenuReducer, { BURGER_MENU_KEY } from './reducers/burgerMenu'
import dirtyFormAlertReducer, { DIRTY_FORM_ALERT_KEY } from './reducers/dirtyFormAlert'
import notificationsReducer, { NOTIFICATIONS_KEY } from './reducers/notifications'

const makeRootReducer = () =>
  combineReducers({
    [AUTH_KEY]: authReducer,
    [BREADCRUMBS_KEY]: breadcrumbsReducer,
    [DEVICE_INFO_KEY]: deviceInfoReducer,
    [FILTERS_KEY]: filtersReducer,
    [BURGER_MENU_KEY]: burgerMenuReducer,
    [DIRTY_FORM_ALERT_KEY]: dirtyFormAlertReducer,
    [NOTIFICATIONS_KEY]: notificationsReducer,
  })

export default makeRootReducer
