import { getBrowserInfo } from '../../utils/getBrowserInfo'

export const DEVICE_INFO_KEY = 'device-info'

const actions = {}
export const initialState = getBrowserInfo()

// ------------------------------------
// Reducer
// ------------------------------------

const reducer = (state = initialState, action) => {
  const handler = actions[action.type]

  return handler ? handler(state, action) : state
}

export default reducer
