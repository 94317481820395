import {
  AdjustmentsIcon,
  BadgeCheckIcon,
  BriefcaseIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardListIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  ChatIcon,
  HomeIcon,
  InboxIcon,
  PlusCircleIcon,
  ChartBarIcon,
  TrashIcon,
  UsersIcon,
  XIcon,
  SearchIcon,
  BellIcon,
  CheckIcon,
  CashIcon,
  ClipboardCheckIcon,
  DotsVerticalIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MailIcon,
  PhoneOutgoingIcon,
  ShieldExclamationIcon,
  PencilIcon,
  PhoneIcon,
  ChatAltIcon,
  PaperClipIcon,
  SaveAsIcon,
  PlayIcon,
  ExclamationIcon,
  ClockIcon,
  MenuIcon,
  RefreshIcon,
  LibraryIcon,
  QuestionMarkCircleIcon,
  HandIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  DownloadIcon,
  DocumentTextIcon,
  PlusIcon,
  ChartPieIcon,
  DocumentAddIcon,
  ArrowSmLeftIcon,
  DocumentReportIcon,
  IdentificationIcon,
  UserGroupIcon,
  FingerPrintIcon,
  EyeIcon,
  EyeOffIcon,
  LogoutIcon,
  FastForwardIcon,
  TerminalIcon,
  GlobeAltIcon,
} from '@heroicons/react/outline'

import DotIcon from './customIcons/DotIcon'
import ConnectorIcon from './customIcons/ConnectorIcon'
import ConnectorHorIcon from './customIcons/ConnectorHorIcon'

const outlineIconComponents = {
  x: XIcon,
  chat: ChatIcon,
  chevronRight: ChevronRightIcon,
  chevronLeft: ChevronLeftIcon,
  chevronDown: ChevronDownIcon,
  chevronUp: ChevronUpIcon,
  home: HomeIcon,
  clipboardList: ClipboardListIcon,
  clipboardCheck: ClipboardCheckIcon,
  chatAlt: ChatAltIcon,
  users: UsersIcon,
  inbox: InboxIcon,
  creditCard: CreditCardIcon,
  chartBar: ChartBarIcon,
  adjustments: AdjustmentsIcon,
  briefcase: BriefcaseIcon,
  badgeCheck: BadgeCheckIcon,
  currencyDollar: CurrencyDollarIcon,
  trash: TrashIcon,
  plusCircle: PlusCircleIcon,
  saveUs: SaveAsIcon,
  paperClip: PaperClipIcon,
  phone: PhoneIcon,
  calendar: CalendarIcon,
  search: SearchIcon,
  bell: BellIcon,
  check: CheckIcon,
  cash: CashIcon,
  dotsVertical: DotsVerticalIcon,
  sortAscending: SortAscendingIcon,
  sortDescending: SortDescendingIcon,
  mail: MailIcon,
  phoneOutgoing: PhoneOutgoingIcon,
  shieldExclamation: ShieldExclamationIcon,
  pencil: PencilIcon,
  play: PlayIcon,
  exclamation: ExclamationIcon,
  clock: ClockIcon,
  menu: MenuIcon,
  refresh: RefreshIcon,
  dot: DotIcon,
  connector: ConnectorIcon,
  connectorHor: ConnectorHorIcon,
  bankTransfer: LibraryIcon,
  questionMarkCircleIcon: QuestionMarkCircleIcon,
  hand: HandIcon,
  chevronDoubleRight: ChevronDoubleRightIcon,
  chevronDoubleLeft: ChevronDoubleLeftIcon,
  download: DownloadIcon,
  document: DocumentTextIcon,
  plus: PlusIcon,
  chartPie: ChartPieIcon,
  documentAdd: DocumentAddIcon,
  arrowSmLeft: ArrowSmLeftIcon,
  documentReport: DocumentReportIcon,
  identification: IdentificationIcon,
  userGroup: UserGroupIcon,
  fingerPrint: FingerPrintIcon,
  eye: EyeIcon,
  eyeOff: EyeOffIcon,
  logout: LogoutIcon,
  fastForward: FastForwardIcon,
  terminal: TerminalIcon,
  globeAlt: GlobeAltIcon,
}

export default outlineIconComponents
