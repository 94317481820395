export const BREADCRUMBS_KEY = 'breadcrumbs'

const actions = {}
const initialState = {
  breadcrumbs: [],
}

// ------------------------------------
// Reducer
// ------------------------------------

const reducer = (state = initialState, action) => {
  const handler = actions[action.type]

  return handler ? handler(state, action) : state
}

export default reducer

// ------------------------------------
// Actions
// ------------------------------------

// Apply breadcrumbs change
export const APPLY_BREADCRUMBS = `${BREADCRUMBS_KEY}/APPLY_BREADCRUMBS`

actions[APPLY_BREADCRUMBS] = (state, { payload }) => ({ breadcrumbs: payload })
