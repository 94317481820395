import appConfig from '../configs/appConfig'

export const ROLLBAR_NO_AUTH_PERSON = {
  id: '0',
  email: 'no_email',
  username: 'no_username',
}

export const getRollbarPerson = (currentUser) => ({
  id: currentUser.id,
  email: currentUser.email,
  username: `${currentUser.firstName} ${currentUser.lastName}`,
})

export const getRollbarConfig = (currentUser) => {
  const environment = appConfig.APP_ENV

  if (!environment) {
    return null
  }

  return {
    accessToken: appConfig.CUSTOMER_ROLLBAR_ACCESS_TOKEN,
    addErrorContext: true,
    autoInstrument: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          code_version: appConfig.GIT_SHA,
          source_map_enabled: true,
          guess_uncaught_frames: true,
        },
      },
      person: currentUser ? getRollbarPerson(currentUser) : ROLLBAR_NO_AUTH_PERSON,
      environment,
    },
  }
}

export const captureInfo = (rollbar, exception, payload = {}) => {
  const exceptionSource = exception instanceof Error ? exception : exception.message || exception
  rollbar?.info(exceptionSource, payload)
}

const getRollbarError = (exception) => {
  if (exception?.extensions && exception?.extensions?.code === 'validationError') {
    return
  }
  return exception instanceof Error ? exception : exception.message || exception
}

export const captureError = (rollbar, exception, payload = {}) => {
  const exceptionSource = getRollbarError(exception)
  if (exceptionSource) {
    rollbar?.error(exceptionSource, payload)
  }
}

export const captureCritical = (rollbar, exception, payload = {}) => {
  const exceptionSource = getRollbarError(exception)
  if (exceptionSource) {
    rollbar?.critical(exceptionSource, payload)
  }
}
