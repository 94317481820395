import React, { useCallback, useState } from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import ClickAwayListener from '@mui/material/ClickAwayListener'

export const tooltipTypes = {
  GREY: 'grey',
  LIGHT: 'light',
}
const getTooltipStyles = (type, isMobile) => {
  if (type === tooltipTypes.GREY)
    return {
      color: '#111827',
      fontSize: '0.825rem',
      backgroundColor: '#FFF9F8',
      maxWidth: isMobile ? 250 : 330,
      border: '1px solid #FBAB9B',
      height: 'fit-content',
    }
  if (type === tooltipTypes.LIGHT) {
    return {
      color: '#111827',
      fontSize: '0.825rem',
      backgroundColor: '#FFFFFF',
      maxWidth: isMobile ? 250 : 330,
      padding: 0,
      boxShadow:
        '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
      height: 'fit-content',
    }
  }
}

export const HtmlTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ type, isMobile }) => ({
  [`& .${tooltipClasses.tooltip}`]: getTooltipStyles(type, isMobile),
}))
const Tooltip = ({
  id,
  disabled,
  children,
  content,
  placement,
  isMobile,
  contentClassName,
  wrapperClassName,
  type,
  isOpen,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const handleTooltipOpen = useCallback(() => setIsOpened(true), [setIsOpened])
  const handleTooltipClose = useCallback(() => setIsOpened(false), [setIsOpened])

  if (disabled || !content) {
    return children
  }

  return isMobile ? (
    <ClickAwayListener
      mouseEvent="onMouseUp"
      onClickAway={handleTooltipClose}
      touchEvent="onTouchEnd">
      <div className={cx('flex items-center', wrapperClassName)}>
        <HtmlTooltip
          id={id}
          isMobile={isMobile}
          open={isOpened}
          placement={placement}
          title={content}
          type={type}>
          <div className={`cursor-pointer ${contentClassName}`} onClick={handleTooltipOpen}>
            {children}
          </div>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  ) : (
    <HtmlTooltip
      id={id}
      isMobile={isMobile}
      open={isOpen}
      placement={placement}
      title={content}
      type={type}>
      <div className={contentClassName}>{children}</div>
    </HtmlTooltip>
  )
}

Tooltip.propTypes = {
  children: PT.element.isRequired,
  content: PT.node,
  disabled: PT.bool,
  id: PT.oneOfType([PT.string, PT.number]),
  placement: PT.string,
  isMobile: PT.bool,
  contentClassName: PT.string,
  wrapperClassName: PT.string,
  type: PT.string,
  isOpen: PT.bool,
}

Tooltip.defaultProps = {
  content: null,
  disabled: false,
  id: null,
  placement: 'left',
  isMobile: false,
  contentClassName: '',
  wrapperClassName: '',
  type: tooltipTypes.GREY,
}

export default Tooltip
