import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { CircularProgress } from '@mui/material'
import classes from './LoadingSpinner.module.scss'
import colors from '../../colors'

const LoadingSpinner = ({ loading, className }) => {
  const containerClassnames = cx(classes.loading, classes.loadingContainer, {
    [className]: !!className,
  })

  if (!loading) {
    return null
  }

  return (
    <div className={containerClassnames}>
      <CircularProgress
        sx={{
          color: colors.SUPPLI_ORANGE,
        }}
      />
    </div>
  )
}

LoadingSpinner.propTypes = {
  className: PT.string,
  loading: PT.bool,
}

LoadingSpinner.defaultProps = {
  className: '',
  loading: true,
}

export default LoadingSpinner
