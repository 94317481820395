import React from 'react'
import cx from 'classnames'
import PT from 'prop-types'

const Section = ({ className, withTitle, children }) => {
  const sectionClassNames = cx(
    'px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 w-full',
    className,
  )
  return withTitle ? (
    <section className={sectionClassNames}>{children}</section>
  ) : (
    <div className={sectionClassNames}>{children}</div>
  )
}

Section.propTypes = {
  className: PT.string,
  withTitle: PT.bool,
  children: PT.node.isRequired,
}
Section.defaultProps = {
  className: '',
  withTitle: false,
}
export default Section
