import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { Flex, FormattedDate, Text } from '../../index'
import sizes from '../../sizes'
import fontWeight from '../../fontWeight'

const EventBox = ({
  className,
  date,
  dateLabel,
  isSelected,
  title,
  secondaryTitle,
  subtitle,
  children,
  onClick,
  customBasis,
}) => {
  const containerClassnames = cx(
    `bg-white shadow overflow-hidden rounded-md px-6 py-4 items-center border 
    hover:border hover:border-primary-700 hover:shadow-2xl`,
    {
      [className]: !!className,
    },
    onClick ? 'cursor-pointer' : '',
    isSelected ? 'border-primary-700' : 'border-white',
  )

  const defaultBasis = [10, 25, 65]
  const basis = [
    `${customBasis[0] || customBasis[0] === 0 ? customBasis[0] : defaultBasis[0]}%`,
    `${customBasis[1] || customBasis[1] === 0 ? customBasis[1] : defaultBasis[1]}%`,
    `${customBasis[2] || customBasis[2] === 0 ? customBasis[2] : defaultBasis[2]}%`,
  ]
  return (
    <Flex className={containerClassnames} onClick={onClick} row>
      {dateLabel || date ? (
        <Flex basis={basis[0]}>
          <Flex alignItems="center" column>
            {dateLabel && (
              <Text className="text-center w-min" size={sizes.SM}>
                {dateLabel}
              </Text>
            )}
            {date && (
              <>
                <Text fontWeight={fontWeight.MEDIUM} size={sizes.XL2}>
                  <FormattedDate date={date} format="d" />
                </Text>

                <Text className="text-gray-500 uppercase" customColor>
                  <FormattedDate date={date} format="LLL" />
                </Text>
              </>
            )}
          </Flex>
        </Flex>
      ) : null}

      <Flex basis={basis[1]} className="pl-2" justifyContent="start" column>
        <Text className={secondaryTitle ? 'mb-1' : 'mb-3'} size={sizes.XL}>
          {title}
        </Text>
        {!!secondaryTitle && (
          <Text
            className={secondaryTitle ? 'mb-1' : 'mb-3'}
            fontWeight={fontWeight.MEDIUM}
            size={sizes.XL}>
            {secondaryTitle}
          </Text>
        )}

        {subtitle}
      </Flex>

      <Flex basis={basis[2]} justifyContent={basis[2] !== '100%' && 'end'}>
        {children}
      </Flex>
    </Flex>
  )
}

EventBox.propTypes = {
  children: PT.node.isRequired,
  className: PT.string,
  date: PT.string.isRequired,
  dateLabel: PT.string,
  isSelected: PT.bool,
  subtitle: PT.node.isRequired,
  title: PT.string.isRequired,
  secondaryTitle: PT.node,
  onClick: PT.func,
  customBasis: PT.arrayOf(PT.number),
}

EventBox.defaultProps = {
  className: '',
  isSelected: false,
  onClick: () => {},
  dateLabel: null,
  secondaryTitle: null,
  customBasis: [],
}

export default EventBox
