import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'

const Box = ({ className, children, padding, onClick }) => {
  const defaultPadding = 'lg:px-6 md:px-3 lg:py-5 md:py-3'
  const clickableClasses = onClick
    ? `hover:border hover:border-primary-700 
    hover:drop-shadow-[0_1px_2px_rgba(0,0,0,0.06)] 
    hover:bg-primary-100 
    cursor-pointer`
    : ''
  const containerClassnames = cx(
    `border border-transparent bg-white shadow rounded-lg overflow-hidden ${clickableClasses}`,
    className,
    padding || defaultPadding,
  )
  return (
    children && (
      <div className={containerClassnames} onClick={onClick}>
        {children}
      </div>
    )
  )
}

export default Box

Box.propTypes = {
  className: PT.string,
  children: PT.node,
  onClick: PT.func,
  padding: PT.string,
}
Box.defaultProps = {
  className: '',
  children: null,
  onClick: null,
  padding: undefined,
}
