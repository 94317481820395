import { merge } from 'lodash'
export const DIRTY_FORM_ALERT_KEY = 'dirty-form-alert'

const actions = {}
const initialState = {
  isFormDirty: false,
  isAlertModalOpened: false,
  'isFormDirty-NewPaymentMethod': false,
  'isAlertModalOpened-NewPaymentMethod': false,
}

// ------------------------------------
// Reducer
// ------------------------------------

const reducer = (state = initialState, action) => {
  const handler = actions[action.type]

  return handler ? handler(state, action) : state
}

export default reducer

// ------------------------------------
// Actions
// ------------------------------------

export const SET_DIRTY_FORM_STATE = `${DIRTY_FORM_ALERT_KEY}/SET_DIRTY_FORM_STATE`

actions[SET_DIRTY_FORM_STATE] = (state, { payload }) =>
  merge({}, state, {
    isFormDirty: payload,
  })

export const SET_ALERT_MODAL_OPENED_STATE = `${DIRTY_FORM_ALERT_KEY}/SET_ALERT_MODAL_OPENED_STATE`

actions[SET_ALERT_MODAL_OPENED_STATE] = (state, { payload }) =>
  merge({}, state, {
    isAlertModalOpened: payload,
  })

export const UPDATE_DIRTY_FORM_ALERT_STATE = `${DIRTY_FORM_ALERT_KEY}/UPDATE_DIRTY_FORM_ALERT_STATE`

actions[UPDATE_DIRTY_FORM_ALERT_STATE] = (state, { payload }) =>
  merge({}, state, {
    [payload.key]: payload.value,
  })

export const RESET_DIRTY_FORM_ALERT_STATE = `${DIRTY_FORM_ALERT_KEY}/RESET_DIRTY_FORM_ALERT_STATE`

actions[RESET_DIRTY_FORM_ALERT_STATE] = (state) =>
  merge({}, state, {
    isFormDirty: false,
    isAlertModalOpened: false,
    'isFormDirty-NewPaymentMethod': false,
    'isAlertModalOpened-NewPaymentMethod': false,
  })
