import React from 'react'
import PT from 'prop-types'
import Dialog, { DIALOG_VARIANTS } from './Dialog'
import Flex from '../spacing/Flex'
import LoadingSpinner from '../progress/LoadingSpinner'

const PageDialog = ({
  children,
  title,
  onModalClose,
  isOpened,
  loading,
  setIsOpened,
  bottomPanel,
  rightPanel,
  isMobile,
  wrapperClass,
  contentClass,
  paperClass,
  headerClass,
  skipAutoFocus,
}) => {
  return (
    <Dialog
      contentClass={contentClass}
      headerClass={headerClass}
      hideCross={isMobile}
      isMobile={isMobile}
      isOpened={isOpened}
      onModalClose={onModalClose}
      paperClass={paperClass}
      setIsOpened={setIsOpened}
      skipAutoFocus={skipAutoFocus}
      title={title}
      variant={DIALOG_VARIANTS.FULLSCREEN}
      wrapperClass={wrapperClass}
      fullScreen
      hideBackdrop>
      {loading && (
        <div className="pt-14 w-screen">
          <LoadingSpinner loading={loading} />
        </div>
      )}
      {!loading && (
        <Flex className="w-full h-full" column>
          <Flex className="flex-1 overflow-y-auto">
            {children}

            {rightPanel && (
              <Flex
                className="bg-white px-8 shadow-4-22-012 pt-14 pb-8 lg:w-96 md:w-80"
                id={'sidebar'}
                column>
                {rightPanel}
              </Flex>
            )}
          </Flex>
          {bottomPanel && (
            <Flex className="flex-row-reverse bg-white py-4 px-8 shadow-m1-2 z-10">
              {bottomPanel}
            </Flex>
          )}
        </Flex>
      )}
    </Dialog>
  )
}

PageDialog.propTypes = {
  loading: PT.bool,
  bottomPanel: PT.node,
  children: PT.node.isRequired,
  isOpened: PT.bool.isRequired,
  onModalClose: PT.func,
  rightPanel: PT.node,
  setIsOpened: PT.func.isRequired,
  title: PT.string,
  wrapperClass: PT.string,
  isMobile: PT.bool,
  contentClass: PT.string,
  paperClass: PT.string,
  headerClass: PT.string,
  skipAutoFocus: PT.bool,
}
PageDialog.defaultProps = {
  bottomPanel: null,
  onModalClose: null,
  rightPanel: null,
  title: '',
  wrapperClass: '',
  contentClass: '',
  isMobile: false,
  paperClass: '',
  headerClass: '',
}

export default PageDialog
