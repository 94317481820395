import React from 'react'
import PT from 'prop-types'
import sizes from '../../sizes'
import defaultAvatar from './defaultAvatar'

const Avatar = ({ imageSrc, firstName, lastName, size }) => {
  const avatarSize = {
    [sizes.XS]: `h-6 w-6 text-${size}`,
    [sizes.SM]: `h-8 w-8 text-${size}`,
    [sizes.BASE]: `h-10 w-10 text-${size}`,
    [sizes.LG]: `h-12 w-12 text-${size}`,
    [sizes.XL]: `h-14 w-14 text-${size}`,
  }

  if (imageSrc) {
    return <img alt="" className={`inline-block rounded-full ${avatarSize[size]}`} src={imageSrc} />
  }

  if (firstName || lastName) {
    const initials = `${firstName ? firstName.charAt(0).toUpperCase() : ''}${
      lastName ? lastName.charAt(0).toUpperCase() : ''
    }`
    return (
      <span
        className={`inline-flex items-center justify-center rounded-full bg-gray-500 white ${avatarSize[size]}`}>
        {initials}
      </span>
    )
  }

  return (
    <span className={`inline-block rounded-full overflow-hidden bg-gray-100 ${avatarSize[size]}`}>
      {defaultAvatar}
    </span>
  )
}

Avatar.propTypes = {
  firstName: PT.string,
  imageSrc: PT.string,
  lastName: PT.string,
  size: PT.oneOf([sizes.XS, sizes.SM, sizes.BASE, sizes.LG, sizes.XL]),
}

Avatar.defaultProps = {
  firstName: '',
  imageSrc: null,
  lastName: '',
  size: sizes.BASE,
}

export default Avatar
