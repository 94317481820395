import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import Flex from '../spacing/Flex'

const Card = ({ className, children, onClick, key, fluidHeight, height, testData }) => {
  const containerClassnames = cx(
    `w-full p-6 bg-white rounded shadow-4-22-022 ${onClick ? 'cursor-pointer' : ''} ${
      fluidHeight ? '' : height
    }`,
    className,
  )
  return (
    <Flex className={containerClassnames} key={key} onClick={onClick} testData={testData} column>
      {children}
    </Flex>
  )
}

Card.propTypes = {
  key: PT.any,
  height: PT.string,
  onClick: PT.func,
  children: PT.node,
  testData: PT.string,
  className: PT.string,
  fluidHeight: PT.bool,
}
Card.defaultProps = {
  key: void 0,
  height: 'h-36',
  onClick: null,
  children: null,
  className: '',
  fluidHeight: false,
}

export default React.memo(Card)
