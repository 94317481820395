import { lazy } from 'react'
import { lazyRetry } from '../utils/lazyRetry'

export const MobileMainPageLoadable = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "mobileMainPage" */ './mobile/index.js'),
    'mobileMainPage',
  ),
)
export const DesktopMainPageLoadable = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "desktopMainPage" */ './desktop/index.js'),
    'desktopMainPage',
  ),
)
