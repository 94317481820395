import React, { useEffect, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { MobileMainPageLoadable, DesktopMainPageLoadable } from './mainPage/loadableComponents'
import { LoadingSpinner } from './ui-kit'
import { useSelector } from 'react-redux'
import { selectDeviceInfo } from './store/selectors/deviceInfo'
import { useDirtyFormAlert } from './hooks/useDirtyFormAlert'

const App = () => {
  const { resetDirtyFormAlertStates } = useDirtyFormAlert()
  const { isMobile } = useSelector(selectDeviceInfo)

  useEffect(() => {
    document.title = 'Customer Suppli'
    resetDirtyFormAlertStates()
  }, [])
  const zoom = document.documentElement.clientWidth / window.innerWidth
  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    if (isMobile) {
      window.addEventListener('resize', appHeight)
      appHeight()
    }
  }, [window.innerHeight, zoom, isMobile])

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner className="w-full h-full" loading />}>
        {isMobile ? <MobileMainPageLoadable /> : <DesktopMainPageLoadable />}
      </Suspense>
    </BrowserRouter>
  )
}

export default App
