import MobileDetected from 'mobile-detect'

export function getBrowserInfo() {
  const md = new MobileDetected(navigator.userAgent)
  let isMobile

  if (window.Cypress) {
    isMobile = !!(md.phone() || md.tablet() || md.mobile() || md.isPhoneSized())
  } else {
    isMobile = !!((md.phone() || md.tablet() || md.mobile()) && md.isPhoneSized())
  }

  const remSize = parseFloat(getComputedStyle(document.documentElement).fontSize)

  return {
    remSize,
    isMobile,
  }
}
