import React from 'react'
import PT from 'prop-types'

const ConnectorIcon = ({ className, onClick }) => (
  <svg
    className={className}
    fill="currentColor"
    height="64"
    onClick={onClick}
    viewBox="0 0 6 64"
    width="6"
    xmlns="http://www.w3.org/2000/svg">
    <rect fill="#E5E7EB" height="64" width="6" />
  </svg>
)

ConnectorIcon.propTypes = {
  className: PT.string,
  onClick: PT.func,
}

ConnectorIcon.defaultProps = {
  className: '',
  onClick: null,
}

export default ConnectorIcon
