import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ApolloProvider } from '@apollo/client'
import { client } from './configs/apollo'
import { Provider } from 'react-redux'
import store from './store'
import enableAxiosInterceptors from './configs/axiosInterceptors'
import initLocales from './i18n-instance'
import '../src/ui-kit/styles/core.scss'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import { getRollbarConfig } from './utils/rollbar'
import { initUserpilot } from './utils/userpilot'
import { LicenseInfo } from '@mui/x-license-pro'
import appConfig from './configs/appConfig'

enableAxiosInterceptors()
initLocales()
initUserpilot()
LicenseInfo.setLicenseKey(appConfig.MUI_LICENSE_KEY)

ReactDOM.render(
  <React.StrictMode>
    <RollbarProvider config={getRollbarConfig(store.getState().auth.currentUser)}>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <App />
          </Provider>
        </ApolloProvider>
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
