export default {
  THIN: 'thin', // font-weight: 100;
  EXTRALIGHT: 'extralight', // font-weight: 200;
  LIGHT: 'light', // font-weight: 300;
  NORMAL: 'normal', // font-weight: 400;
  MEDIUM: 'medium', // font-weight: 500;
  SEMIBOLD: 'semibold', // font-weight: 600;
  BOLD: 'bold', // font-weight: 700;
  EXTRABOLD: 'extrabold', // font-weight: 800;
  BLACK: 'black ', // font-weight: 900;
}
