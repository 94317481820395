import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'

const InputWrapper = ({ children, id, label, errorMessage, helpText, className, withIcon }) => {
  const childrenContainerClasses = cx({
    ['mt-1']: !!label,
    relative: withIcon,
  })

  return (
    <div className={className}>
      {!!label && (
        <label className="block text-sm font-medium text-gray-700" htmlFor={id}>
          {label}
        </label>
      )}

      <div className={childrenContainerClasses}>{children}</div>

      {!!helpText && (
        <p className="mr-1 mt-1 text-warmBlack-400 text-right" id={`${id}-help-text`}>
          {helpText}
        </p>
      )}

      {!!errorMessage && (
        <p className="mt-2 text-sm text-error" id={`${id}-help-text`}>
          {errorMessage}
        </p>
      )}
    </div>
  )
}

InputWrapper.propTypes = {
  children: PT.node.isRequired,
  className: PT.string,
  errorMessage: PT.string,
  helpText: PT.string,
  id: PT.string.isRequired,
  label: PT.string,
  withIcon: PT.bool,
}

InputWrapper.defaultProps = {
  className: '',
  errorMessage: '',
  helpText: '',
  label: '',
  withIcon: false,
}

export default InputWrapper
